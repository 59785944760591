import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { useAppSelector } from '../../hooks/reduxHooks';
import { setError } from '../../store/errorSlice';
import { API_TRANSLATIONS } from '../../constants/api';
import { IAboutPage } from '../../types/types';
import axios from 'axios';

import Seo from '../../components/Seo/Seo';
import Greeting from '../../components/Greeting/Greeting';
import VideoBlock from '../../components/VideoBlock/VideoBlock';
import AboutBlock from '../../components/AboutBlock/AboutBlock';
import Partners from '../../components/Partners/Partners';
// import Benefits from '../../components/Benefits/Benefits';
import Gallery from '../../components/Gallery/Gallery';
import Faq from '../../components/Faq/Faq';
import Histrory from '../../components/History/History';
import Spinner from '../../components/Spinner/Spinner';
import ErrorBlock from '../../components/ErrorBlock/ErrorBlock';

import gallery3 from '../../assets/img/gallery-3.jpg';
import gallery4 from '../../assets/img/gallery-4.jpg';
import gallery5 from '../../assets/img/gallery-5.jpg';
import gallery6 from '../../assets/img/gallery-6.jpg';
import certificate1 from '../../assets/img/certificate1.jpg';
import certificate2 from '../../assets/img/certificate2.jpg';
import certificate3 from '../../assets/img/certificate3.jpg';
import certificate4 from '../../assets/img/certificate4.jpg';
import certificate5 from '../../assets/img/certificate5.jpg';
import certificate6 from '../../assets/img/certificate6.jpg';
import certificate7 from '../../assets/img/certificate7.jpg';
import certificate8 from '../../assets/img/certificate8.jpg';
import video from '../../assets/video/apples.mp4';

import './AboutPage.scss';

const AboutPage:FC = () => {
    const [aboutPageInfo, setAboutPageInfo] = useState<IAboutPage>();
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const currentLanguage = useAppSelector(state => state.languages.curentLang);
    const error = useAppSelector(state => state.error.error);

    const getAboutPageInfo = async () => {
        try {
            setLoading(true);
            const res = await axios.get(API_TRANSLATIONS + currentLanguage.id);
            if(res.status === 200) {
                setAboutPageInfo(res.data[0].data.about_page);
                dispatch(setError(false));
            }
        } catch (error) {
            dispatch(setError(true))
        } finally {
            setLoading(false);
        }
    }
    const { t } = useTranslation();
    const photos = [
        {id: 3, img: gallery3},
        {id: 4, img: gallery4},
        {id: 5, img: gallery5},
        {id: 6, img: gallery6}
    ];
    const certificates = [
        {id: 1, img: certificate1},
        {id: 2, img: certificate2},
        {id: 3, img: certificate3},
        {id: 4, img: certificate4},
        {id: 5, img: certificate5},
        {id: 6, img: certificate6},
        {id: 7, img: certificate7},
        {id: 8, img: certificate8},
    ] 

    useEffect(() => {
        window.scrollTo(0, 0);
        getAboutPageInfo();
    }, [currentLanguage])

    return (
        <>
            <Seo 
                title="Vitamin2015 «EVA» | Про нас"
                description="Дізнайтеся більше про 'Vitamin2015 Eva' – нашу команду експертів у світі смаку та здоров'я. Ми Вітамін2015 «EVA» – ваш вірний партнер у наданні натуральних соків, зброжених соків, фруктових-овочевих сиропів та концентрованих чаїв. Наша місія – робити ваше здоров'я та задоволення від смаку нашим пріоритетом. Діліться з нами нашою страстю до якісних продуктів, які допомагають вам насолоджуватися кожним моментом життя. Відкрийте для себе нашу історію, наші цінності та те, що відрізняє 'Vitamin2015 Eva' в світі здорового харчування."
            />
            <div className='about-page'>
            {loading ? <Spinner /> : (
                <>
                {error ? <ErrorBlock /> : (
                    aboutPageInfo && (
                        <>
                            <Greeting
                                title={aboutPageInfo.greeting.title} 
                                text={aboutPageInfo.greeting.text}
                                img={aboutPageInfo.greeting.img}
                            />
                            <VideoBlock title={t("about_page.company.title")} video={video} />
                            <AboutBlock sections={aboutPageInfo.company.sections} />
                            <Histrory title={aboutPageInfo.history.title} sections={aboutPageInfo.history.sections} />
                            <Partners title={aboutPageInfo.partners.title} photos={aboutPageInfo.partners.photos} />
                            {/* <Benefits title={aboutPageInfo.benefits.title} sections={aboutPageInfo.benefits.sections} /> */}
                            <Gallery title={aboutPageInfo.gallery.title} photos={photos} />
                            {/* add photos from api in qualitySystem */}
                            <Gallery title={aboutPageInfo.quality_system.title} photos={certificates} photoType='rectangle' />
                            <Faq title={aboutPageInfo.faq.title} sections={aboutPageInfo.faq.sections} />
                        </>
                    )
                )}
                </>
            )}
        </div>
        </>
    )
}

export default AboutPage;